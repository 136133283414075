import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  IconButton,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

const MCQUploadDialog = ({ open, onClose, onSubmit }) => {
    const [file, setFile] = useState(null);
    const [prompt, setPrompt] = useState('');
    const [numQuestions, setNumQuestions] = useState(5);
    const [difficulty, setDifficulty] = useState('medium');
    const [topics, setTopics] = useState([]);
    const [newTopic, setNewTopic] = useState('');

    // Reset all states when dialog closes
    const handleClose = () => {
        setFile(null);
        setPrompt('');
        setNumQuestions(5);
        setDifficulty('medium');
        setTopics([]);
        setNewTopic('');
        onClose();
    };

    // Handle submit and reset
    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('prompt', prompt);
        formData.append('numQuestions', numQuestions);
        formData.append('difficulty', difficulty);
        formData.append('topics', JSON.stringify(topics));

        onSubmit(formData);
        handleClose(); // Reset and close dialog after submission
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleAddTopic = () => {
        if (newTopic.trim() && !topics.includes(newTopic.trim())) {
            setTopics([...topics, newTopic.trim()]);
            setNewTopic('');
        }
    };

    const handleRemoveTopic = (topicToRemove) => {
        setTopics(topics.filter(topic => topic !== topicToRemove));
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Generate MCQs from File
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Button
                        variant="outlined"
                        component="label"
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload File
                        <input
                          type="file"
                          hidden
                          onChange={handleFileChange}
                          accept=".pdf,.doc,.docx,.txt"
                        />
                    </Button>
                    {file && (
                        <Typography variant="body2" color="textSecondary">
                            Selected file: {file.name}
                        </Typography>
                    )}

                    <TextField
                        fullWidth
                        label="Custom Prompt"
                        multiline
                        rows={3}
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        placeholder="E.g., Generate MCQs focusing on key concepts and clinical scenarios..."
                    />

                    <FormControl fullWidth>
                        <InputLabel>Number of Questions</InputLabel>
                        <Select
                          value={numQuestions}
                          onChange={(e) => setNumQuestions(e.target.value)}
                          label="Number of Questions"
                        >
                          {[5, 10, 15, 20].map(num => (
                            <MenuItem key={num} value={num}>{num}</MenuItem>
                          ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel>Difficulty Level</InputLabel>
                        <Select
                          value={difficulty}
                          onChange={(e) => setDifficulty(e.target.value)}
                          label="Difficulty Level"
                        >
                          <MenuItem value="easy">Easy</MenuItem>
                          <MenuItem value="medium">Medium</MenuItem>
                          <MenuItem value="hard">Hard</MenuItem>
                        </Select>
                    </FormControl>

                    <Box>
                        <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                            <TextField
                                label="Add Topics"
                                value={newTopic}
                                onChange={(e) => setNewTopic(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleAddTopic()}
                            />
                            <Button onClick={handleAddTopic}>Add</Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {topics.map((topic) => (
                                <Chip
                                  key={topic}
                                  label={topic}
                                  onDelete={() => handleRemoveTopic(topic)}
                                />
                              ))}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button 
                    onClick={handleSubmit} 
                    variant="contained" 
                    disabled={!file}
                >
                    Generate MCQs
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MCQUploadDialog;